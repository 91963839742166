import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  Grid,
  ImageList,
  Pagination,
  PaginationItem,
  Typography,
  Zoom,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import getPhotos from "../service/getPhotos";
import CloseIcon from "@mui/icons-material/Close";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import config from "../resources/backend";
const Gallery = React.lazy(() => import("./Gallery"));

export default function Gallerys() {
  const [constructorHasRun, setConstructorHasRun] = useState(false);
  const [images, setImages] = useState([]);
  const [totalImages, setTotalImages] = useState([[]]);
  const [currentImage, setCurrentImage] = useState();
  const [currentTitle, setCurrentTitle] = useState("");
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(5);

  if (!constructorHasRun) {
    getPhotos(setTotalImages);
    setConstructorHasRun(true);
  }

  useEffect(() => {
    setTotalPages(totalImages.length);
    setImages(totalImages[page - 1]);
  }, [page, images, totalImages]);

  return (
    <>
      <Typography
        gutterBottom
        variant="h5"
        component="div"
        align="center"
        style={{ padding: 40 }}
      >
        Gallery
      </Typography>
      <ImageList sx={{ width: "100%" }} variant="woven" cols={4} gap={16}>
        {images.map((image) => (
          <>
            <React.Suspense fallback={<CircularProgress />}>
              <Gallery
                // key={image.id}
                setCurrentTitle={setCurrentTitle}
                image={image}
                setCurrentImage={setCurrentImage}
              />
            </React.Suspense>
          </>
        ))}
      </ImageList>
      <Dialog
        open={currentImage}
        onClose={() => setCurrentImage(null)}
        aria-labelledby="image-modal"
        style={{ backgroundColor: "transparent", boxShadow: "none" }}
        overlayStyle={{ backgroundColor: "transparent" }}
      >
        <Zoom in={true} {...{ timeout: 1000 }}>
          <Box
            sx={{
              width: "100%",
            }}
          >
            <div id="image-modal">
              <Button onClick={() => setCurrentImage(null)}>
                <CloseIcon />
              </Button>
              <img
                src={`${config.href}/${currentImage}`}
                alt="CurrentImageClicked"
                srcSet={`${config.href}/${currentImage}`}
                className="img-fluid"
              />
              <div className="row" style={{ padding: 20 }}>
                <h5>{currentTitle}</h5>
              </div>
            </div>
          </Box>
        </Zoom>
      </Dialog>
      {totalImages.length !== 1 && (
        <Grid container alignItems="center" justifyContent="center">
          <Pagination
            defaultPage={page}
            onChange={(event, value) => {
              setPage(value);
            }}
            count={totalPages}
            variant="outlined"
            renderItem={(item) => (
              <PaginationItem
                components={{ previous: ArrowBackIcon, next: ArrowForwardIcon }}
                {...item}
              />
            )}
          />
        </Grid>
      )}
    </>
  );
}
