import { Alert, AlertTitle } from "@mui/material";
import React, { Component } from "react";

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    console.log(error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return (
        <Alert severity="error">
          <AlertTitle>Something Went Wrong</AlertTitle>
          There was an error occured
        </Alert>
      );
    } else {
      return this.props.children;
    }
  }
}

export default ErrorBoundary;
