import { Skeleton } from "@mui/material";
import React from "react";
// import Loading from "../loading/Loading";
import Contents from "../content/Contents";
import Educations from "../education/Educations";
import Gallerys from "../gallery/Gallerys";
import Achivements from "../achivement/Achivements";
import Contributions from "../contribution/Contributions";
import Loading from "../loading/Loading";
const ProfileCard = React.lazy(() => import("../profile/ProfileCard"));
const Quotes = React.lazy(() => import("../quote/Quotes"));
const Bio = React.lazy(() => import("../bio/Bio"));
// const Contents = React.lazy(() => import("../content/Contents"));
// const Educations = React.lazy(() => import("../education/Educations"));
// const Gallerys = React.lazy(() => import("../gallery/Gallerys"));
// const Achivements = React.lazy(() => import("../achivement/Achivements"));
// const Contributions = React.lazy(() => import("../contribution/Contributions"));

export default function Home() {
  return (
    <>
      <React.Suspense
        fallback={
          <>
            <br />
            <Skeleton variant="rectangular" height={200} />
            <br />
          </>
        }
      >
        <ProfileCard />
      </React.Suspense>
      <React.Suspense
        fallback={
          <>
            <br />
            <Skeleton variant="rectangular" height={200} />
            <br />
          </>
        }
      >
        <Bio />
      </React.Suspense>
      {/* <React.Suspense
        fallback={<Skeleton variant="rectangular" height={120} />}
      > */}
      <Contents />
      {/* </React.Suspense> */}
      {/* <React.Suspense fallback={<Loading />}> */}
      <Educations />
      {/* </React.Suspense> */}
      {/* <React.Suspense fallback={<Loading />}> */}
      <Gallerys />
      {/* </React.Suspense> */}
      {/* <React.Suspense fallback={<Loading />}> */}
      <Achivements />
      {/* </React.Suspense> */}
      {/* <React.Suspense fallback={<Loading />}> */}
      <Contributions />
      {/* </React.Suspense> */}
      <React.Suspense fallbac={<Loading />}>
        <Quotes />
      </React.Suspense>
    </>
  );
}
