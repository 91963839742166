import {
  Grid,
  Pagination,
  PaginationItem,
  Typography,
  Unstable_Grid2 as UnstableGrid2,
  Zoom,
} from "@mui/material";
import React, { useContext, useState } from "react";
import getContributions from "../service/getContributions";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { useEffect } from "react";
import Context from "../context/Context";
import Loading from "../loading/Loading";
const Contribution = React.lazy(() => import("./Contribution"));

export default function Contributions() {
  const [items, setItems] = useState([]);
  const [totalItems, setTotalItems] = useState([[]]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [constructorHasRun, setConstrucorHasRun] = useState(false);
  const { isMobile } = useContext(Context);

  if (!constructorHasRun) {
    getContributions(setTotalItems);
    setConstrucorHasRun(true);
  }

  useEffect(() => {
    setTotalPages(totalItems.length);
    setItems(totalItems[page - 1]);
    // alert(JSON.stringify(items));
  }, [items, page, totalItems]);

  return (
    <>
      {items.length ? (
        <Typography
          gutterBottom
          variant="h5"
          component="div"
          align="center"
          style={{ padding: 40 }}
        >
          Contributions
        </Typography>
      ) : null}

      <Zoom in={true} {...{ timeout: 500 }}>
        <UnstableGrid2
          container
          columnSpacing={{ xs: 1, sm: 3, md: 3 }}
          sx={{
            "& > :not(style)": {
              m: 1,
              width: isMobile ? "100%" : 375,
            },
          }}
        >
          {items.map((item) => {
            return (
              <React.Suspense fallback={<Loading />}>
                <Contribution
                  id={item.id}
                  headline={item.headline}
                  description={item.description}
                />
              </React.Suspense>
            );
          })}
        </UnstableGrid2>
      </Zoom>
      {totalItems.length !== 1 && (
        <Grid container alignItems="center" justifyContent="center">
          <Pagination
            defaultPage={page}
            onChange={(event, value) => {
              setPage(value);
            }}
            count={totalPages}
            variant="outlined"
            renderItem={(item) => (
              <PaginationItem
                components={{
                  previous: ArrowBackIcon,
                  next: ArrowForwardIcon,
                }}
                {...item}
              />
            )}
          />
        </Grid>
      )}
    </>
  );
}
