import {
  Container,
  createTheme,
  CssBaseline,
  ThemeProvider,
  useMediaQuery,
} from "@mui/material";
import React, { useState } from "react";
import Context from "./context/Context";
import Routing from "./routing/Routing";
import checkToken from "./service/checkToken";
import Loading from "./loading/Loading";
const Footer = React.lazy(() => import("./footer/Footer"));
const GuestNavBar = React.lazy(() => import("./header/GuestNavBar"));
const AppNavBar = React.lazy(() => import("./header/AppNavBar"));

function App() {
  const [theme, setTheme] = useState(
    localStorage.getItem("theme") ? localStorage.getItem("theme") : "dark"
  );

  const [content, setContent] = useState({});

  // const isMobile = useMediaQuery("(max-width:600px)");
  // const isMobile = useMediaQuery("(max-width:603px)");
  const isMobile = useMediaQuery("(max-width:781px)");
  // const isMobile = useMediaQuery("(max-width:607px)");
  // const isMobile = useMediaQuery("(max-width:615px)");

  const changeTheme = () => {
    if (theme === "light") {
      setTheme("dark");
      localStorage.setItem("theme", "dark");
    } else {
      setTheme("light");
      localStorage.setItem("theme", "light");
    }
  };

  return (
    <Context.Provider
      value={{
        changeTheme: changeTheme,
        theme: theme,
        isMobile: isMobile,
        content: content,
        setContent: setContent,
      }}
    >
      <ThemeProvider theme={createTheme({ palette: { mode: theme } })}>
        <Container>
          <CssBaseline />
          <React.Suspense fallback={<Loading />}>
            <GuestNavBar />
          </React.Suspense>
          <Routing />
          <React.Suspense fallback={<Loading />}>
            <Footer />
          </React.Suspense>
        </Container>
      </ThemeProvider>
    </Context.Provider>
  );
}

export default App;
