import photo1 from "../images/gallery/photo1.jpg";
import photo2 from "../images/gallery/photo2.jpg";
import photo3 from "../images/gallery/photo3.jpg";
import photo4 from "../images/gallery/photo4.jpg";
import photo5 from "../images/gallery/photo5.jpg";
import photo6 from "../images/gallery/photo6.jpg";
import photo7 from "../images/gallery/photo7.jpg";
import photo8 from "../images/gallery/photo8.jpg";
import photo9 from "../images/gallery/photo9.jpg";
import photo10 from "../images/gallery/photo10.jpg";
import photo11 from "../images/gallery/photo11.jpg";
import photo12 from "../images/gallery/photo12.jpg";
import photo13 from "../images/gallery/photo13.jpg";
import photo14 from "../images/gallery/photo14.jpg";
import photo15 from "../images/gallery/photo15.jpg";
import photo16 from "../images/gallery/photo16.jpg";
import photo17 from "../images/gallery/photo17.jpg";
import photo18 from "../images/gallery/photo18.jpg";
import photo19 from "../images/gallery/photo19.jpg";
import photo20 from "../images/gallery/photo20.jpg";
import photo21 from "../images/gallery/photo21.jpg";
import photo22 from "../images/gallery/photo22.jpg";
import photo23 from "../images/gallery/photo23.jpg";
import photo24 from "../images/gallery/photo24.jpg";
import photo25 from "../images/gallery/photo25.jpg";
import photo26 from "../images/gallery/photo26.jpg";
import photo27 from "../images/gallery/photo27.jpg";
import photo28 from "../images/gallery/photo28.jpg";
import photo29 from "../images/gallery/photo29.jpg";
import photo30 from "../images/gallery/photo30.jpg";
import photo31 from "../images/gallery/photo31.jpg";
import photo32 from "../images/gallery/photo32.jpg";
import photo33 from "../images/gallery/photo33.jpg";
import photo34 from "../images/gallery/photo34.jpg";
import photo35 from "../images/gallery/photo35.jpg";
import photo36 from "../images/gallery/photo36.jpg";
import photo37 from "../images/gallery/photo37.jpg";
import photo38 from "../images/gallery/photo38.jpg";
import photo39 from "../images/gallery/photo39.jpg";
import photo40 from "../images/gallery/photo40.jpg";
import photo41 from "../images/gallery/photo41.jpg";
import photo42 from "../images/gallery/photo42.jpg";
import photo43 from "../images/gallery/photo43.jpg";
import photo44 from "../images/gallery/photo44.jpg";
import photo45 from "../images/gallery/photo45.jpg";
import photo46 from "../images/gallery/photo46.jpg";
import photo47 from "../images/gallery/photo47.jpg";
import photo48 from "../images/gallery/photo48.jpg";
import photo49 from "../images/gallery/photo49.jpg";
import photo50 from "../images/gallery/photo50.jpg";
import photo51 from "../images/gallery/photo51.jpg";
import photo52 from "../images/gallery/photo52.jpg";
import config from "../resources/backend";
import axios from "axios";

export default function getPhotos(set) {
  if (config.backend) {
    axios
      .get(`${config.url}/photos/fetch/pages/`)
      .then(({ data }) => {
        set(data);
        // alert(data);
      })
      .catch((error) => {
        console.log(error);
        set([
          [
            {
              img: photo13,
            },
            {
              img: photo14,
            },
            {
              img: photo15,
            },
            {
              img: photo16,
            },
          ],

          [
            {
              img: photo17,
            },
            {
              img: photo18,
            },
            {
              img: photo19,
            },
            {
              img: photo20,
            },
          ],

          [
            {
              img: photo9,
            },
            {
              img: photo10,
            },
            {
              img: photo11,
            },
            {
              img: photo12,
            },
          ],

          [
            {
              img: photo1,
            },
            {
              img: photo2,
            },
            {
              img: photo3,
            },
            {
              img: photo4,
            },
          ],

          [
            {
              img: photo5,
            },
            {
              img: photo6,
            },
            {
              img: photo7,
            },
            {
              img: photo8,
            },
          ],

          [
            {
              img: photo21,
            },
            {
              img: photo22,
            },
            {
              img: photo23,
            },
            {
              img: photo24,
            },
          ],
          [
            {
              img: photo25,
            },
            {
              img: photo26,
            },

            {
              img: photo27,
            },
            {
              img: photo28,
            },
          ],
          [
            {
              img: photo29,
            },
            {
              img: photo30,
            },

            {
              img: photo31,
            },
            {
              img: photo32,
            },
          ],
          [
            {
              img: photo33,
            },
            {
              img: photo34,
            },
            {
              img: photo35,
            },
            {
              img: photo36,
            },
          ],
          [
            {
              img: photo37,
            },
            {
              img: photo38,
            },
            {
              img: photo39,
            },
            {
              img: photo40,
            },
          ],
          [
            {
              img: photo41,
            },
            {
              img: photo42,
            },
            {
              img: photo43,
            },
            {
              img: photo44,
            },
          ],
          [
            {
              img: photo45,
            },
            {
              img: photo46,
            },
            {
              img: photo47,
            },
            {
              img: photo48,
            },
          ],
          [
            {
              img: photo49,
            },
            {
              img: photo50,
            },
            {
              img: photo51,
            },
            {
              img: photo52,
            },
          ],
        ]);
      });
  } else {
    set([
      [
        {
          img: photo13,
        },
        {
          img: photo14,
        },
        {
          img: photo15,
        },
        {
          img: photo16,
        },
      ],

      [
        {
          img: photo17,
        },
        {
          img: photo18,
        },
        {
          img: photo19,
        },
        {
          img: photo20,
        },
      ],

      [
        {
          img: photo9,
        },
        {
          img: photo10,
        },
        {
          img: photo11,
        },
        {
          img: photo12,
        },
      ],

      [
        {
          img: photo1,
        },
        {
          img: photo2,
        },
        {
          img: photo3,
        },
        {
          img: photo4,
        },
      ],

      [
        {
          img: photo5,
        },
        {
          img: photo6,
        },
        {
          img: photo7,
        },
        {
          img: photo8,
        },
      ],

      [
        {
          img: photo21,
        },
        {
          img: photo22,
        },
        {
          img: photo23,
        },
        {
          img: photo24,
        },
      ],
      [
        {
          img: photo25,
        },
        {
          img: photo26,
        },

        {
          img: photo27,
        },
        {
          img: photo28,
        },
      ],
      [
        {
          img: photo29,
        },
        {
          img: photo30,
        },

        {
          img: photo31,
        },
        {
          img: photo32,
        },
      ],
      [
        {
          img: photo33,
        },
        {
          img: photo34,
        },
        {
          img: photo35,
        },
        {
          img: photo36,
        },
      ],
      [
        {
          img: photo37,
        },
        {
          img: photo38,
        },
        {
          img: photo39,
        },
        {
          img: photo40,
        },
      ],
      [
        {
          img: photo41,
        },
        {
          img: photo42,
        },
        {
          img: photo43,
        },
        {
          img: photo44,
        },
      ],
      [
        {
          img: photo45,
        },
        {
          img: photo46,
        },
        {
          img: photo47,
        },
        {
          img: photo48,
        },
      ],
      [
        {
          img: photo49,
        },
        {
          img: photo50,
        },
        {
          img: photo51,
        },
        {
          img: photo52,
        },
      ],
    ]);
  }
}
