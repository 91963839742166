import axios from "axios";
import cert1 from "../images/CertPlus_1.jpg";
import cert2 from "../images/CertPlus_2.jpg";
import cert3 from "../images/CertPlus_3.jpg";
import cert4 from "../images/CertPlus_4.jpg";
import cert5 from "../images/CertPlus_5.jpg";
import cert6 from "../images/CertPlus_6.jpg";
import cert7 from "../images/CertPlus_7.jpg";
import cert8 from "../images/CertPlus_8.jpg";
import config from "../resources/backend";

export default function getAchivements(set) {
  if (config.backend) {
    axios
      .get(`${config.url}/achivements/fetch/pages/`)
      .then(({ data }) => {
        set(data);
      })
      .catch((error) => {
        console.log(error);
        set([
          [
            {
              thumbnail: cert1,
              name: "100 meter Sprint",
              date: "06 February, 1992",
            },
            {
              thumbnail: cert2,
              name: "English Language Training",
              date: "18 August, 2001 - 06 September, 2001",
            },
            {
              thumbnail: cert3,
              name: "TOT on Online Multimidea Content Development Cource",
              date: "11 October, 2017 - 31 October, 2017",
            },
          ],
          [
            {
              thumbnail: cert4,
              name: "Acting Competetion",
              date: "1998",
            },
            {
              thumbnail: cert5,
              name: "80 meter Sprint",
              date: "17 March, 1985",
            },
            {
              thumbnail: cert6,
              name: "Acting Competetion",
              date: "28 February, 1989",
            },
          ],
          [
            {
              thumbnail: cert7,
              name: "Training Cource Under DPED Programme",
              date: "17 March, 2013",
            },
            {
              name: "TOT Training on Inclusive Education and Disability Orientation",
              date: "25 November, 2021",
              thumbnail: cert8,
            },
          ],
        ]);
      });
  } else {
    set([
      [
        {
          thumbnail: cert1,
          name: "100 meter Sprint",
          date: "06 February, 1992",
        },
        {
          thumbnail: cert2,
          name: "English Language Training",
          date: "18 August, 2001 - 06 September, 2001",
        },
        {
          thumbnail: cert3,
          name: "TOT on Online Multimidea Content Development Cource",
          date: "11 October, 2017 - 31 October, 2017",
        },
      ],
      [
        {
          thumbnail: cert4,
          name: "Acting Competetion",
          date: "1998",
        },
        {
          thumbnail: cert5,
          name: "80 meter Sprint",
          date: "17 March, 1985",
        },
        {
          thumbnail: cert6,
          name: "Acting Competetion",
          date: "28 February, 1989",
        },
      ],
      [
        {
          thumbnail: cert7,
          name: "Training Cource Under DPED Programme",
          date: "17 March, 2013",
        },
        {
          name: "TOT Training on Inclusive Education and Disability Orientation",
          date: "25 November, 2021",
          thumbnail: cert8,
        },
      ],
    ]);
  }
}
