import axios from "axios";
import config from "../resources/backend";
import thumbnail3 from "../images/thumbnail/thumbnail3.png";
import thumbnail4 from "../images/thumbnail/thumbnail4.png";
import thumbnail5 from "../images/thumbnail/thumbnail5.png";
import thumbnail6 from "../images/thumbnail/thumbnail6.png";
import thumbnail7 from "../images/thumbnail/thumbnail7.png";
import thumbnail8 from "../images/thumbnail/thumbnail8.png";
import thumbnail9 from "../images/thumbnail/thumbnail9.png";
import thumbnail10 from "../images/thumbnail/thumbnail10.png";

const defaultData = [
  [
    {
      id: 1,
      title: "Division",
      description: "Some Thing More Descroption here",
      thumbnail: thumbnail3,
      link: "https://docs.google.com/presentation/d/e/2PACX-1vTGqpzNgfRDXYLcYhJLxZC8LKO-d75dBwJvY_xHU_hUsY_04OXGPXOmmhum-7J_pw/embed?start=false&loop=false&delayms=3000",
    },
    {
      id: 2,
      title: "Arts And Crafts",
      description: "Some Thing More Descroption here",
      thumbnail: thumbnail4,
      link: "https://docs.google.com/presentation/d/e/2PACX-1vTmXaL0T3X4-JYaQoryV7GSquy2wHbXW1wdwxF-8XHoICQZOhvNk5v1O0V3llxFbQ/embed?start=false&loop=false&delayms=3000",
    },
    {
      id: 3,
      title: "Math For Class 4",
      description: "Some Thing More Descroption here",
      thumbnail: thumbnail5,
      link: "https://docs.google.com/presentation/d/e/2PACX-1vQOn4TWvUGKM9blbFrOhPrbIZeBCnixQ0IDHN-0Kc4koYdfZAxIRwg2KIDFIVN9UA/embed?start=false&loop=false&delayms=3000",
    },
  ],

  [
    {
      id: 4,
      title: "Multiplication and Division",
      description: "Some Thing More Descroption here",
      thumbnail: thumbnail6,
      link: "https://docs.google.com/presentation/d/e/2PACX-1vSJmDSb8_Qo3cnv9xIKPa3eLYK0wcRc5RwbOwQvBcaMgb1IriF6vbYQzgYnHHaMPQ/embed?start=false&loop=false&delayms=3000",
    },
    {
      id: 5,
      title: "Least Common Multiple",
      description: "Some Thing More Descroption here",
      thumbnail: thumbnail7,
      link: "https://docs.google.com/presentation/d/e/2PACX-1vQDp0gfTL5qWxAh1jTjfGLbGHbke3AxjdVBxq2HU-EIHbyPu1IJk6WVCQcMZxscPA/embed?start=false&loop=false&delayms=3000",
    },
    {
      id: 6,
      title: "Addition and Subtraction",
      description: "Some Thing More Descroption here",
      thumbnail: thumbnail8,
      link: "https://docs.google.com/presentation/d/e/2PACX-1vScMpA_yiBmOTNP547BNKGOUIg1hIAdn-tFjQv9quN75YvFUgU3GeodO-dM9FxDCg/embed?start=false&loop=false&delayms=3000",
    },
  ],
  [
    {
      id: 7,
      title: "Aim of Math",
      description: "Some Thing More Descroption here",
      thumbnail: thumbnail9,
      link: "https://docs.google.com/presentation/d/e/2PACX-1vQCpVUVdo_UR7bc9bLQUN-cqqHXzfen6-NYALsx1-Zy5bK_bhxSO29_VUCbF0S2SQ/embed?start=false&loop=false&delayms=3000",
    },
    {
      id: 8,
      title: "Math PK Content",
      description: "Some Thing More Descroption here",
      thumbnail: thumbnail10,
      link: "https://docs.google.com/presentation/d/e/2PACX-1vTVjvHi01eHyP4bHUAoAoJALTWz-L3OYwNcwdmkI5xehylJPxwialAe8QHHBeXATA/embed?start=false&loop=false&delayms=3000",
    },
  ],
];

export default function getContents(set) {
  if (config.backend) {
    console.log("Backend");
    axios
      .get(`${config.url}/contents/fetch/pages/`)
      .then(({ data }) => {
        console.log(JSON.stringify(data));
        set(data);
      })
      .catch((error) => {
        console.log(error);
        set(defaultData);
      });
  } else {
    set(defaultData);
  }
}

const onlyData = [
  {
    id: 1,
    title: "Division",
    description: "Some Thing More Descroption here",
    thumbnail: thumbnail3,
    link: "https://docs.google.com/presentation/d/e/2PACX-1vTGqpzNgfRDXYLcYhJLxZC8LKO-d75dBwJvY_xHU_hUsY_04OXGPXOmmhum-7J_pw/embed?start=false&loop=false&delayms=3000",
  },
  {
    id: 2,
    title: "Arts And Crafts",
    description: "Some Thing More Descroption here",
    thumbnail: thumbnail4,
    link: "https://docs.google.com/presentation/d/e/2PACX-1vTmXaL0T3X4-JYaQoryV7GSquy2wHbXW1wdwxF-8XHoICQZOhvNk5v1O0V3llxFbQ/embed?start=false&loop=false&delayms=3000",
  },
  {
    id: 3,
    title: "Math For Class 4",
    description: "Some Thing More Descroption here",
    thumbnail: thumbnail5,
    link: "https://docs.google.com/presentation/d/e/2PACX-1vQOn4TWvUGKM9blbFrOhPrbIZeBCnixQ0IDHN-0Kc4koYdfZAxIRwg2KIDFIVN9UA/embed?start=false&loop=false&delayms=3000",
  },
  {
    id: 4,
    title: "Multiplication and Division",
    description: "Some Thing More Descroption here",
    thumbnail: thumbnail6,
    link: "https://docs.google.com/presentation/d/e/2PACX-1vSJmDSb8_Qo3cnv9xIKPa3eLYK0wcRc5RwbOwQvBcaMgb1IriF6vbYQzgYnHHaMPQ/embed?start=false&loop=false&delayms=3000",
  },
  {
    id: 5,
    title: "Least Common Multiple",
    description: "Some Thing More Descroption here",
    thumbnail: thumbnail7,
    link: "https://docs.google.com/presentation/d/e/2PACX-1vQDp0gfTL5qWxAh1jTjfGLbGHbke3AxjdVBxq2HU-EIHbyPu1IJk6WVCQcMZxscPA/embed?start=false&loop=false&delayms=3000",
  },
  {
    id: 6,
    title: "Addition and Subtraction",
    description: "Some Thing More Descroption here",
    thumbnail: thumbnail8,
    link: "https://docs.google.com/presentation/d/e/2PACX-1vScMpA_yiBmOTNP547BNKGOUIg1hIAdn-tFjQv9quN75YvFUgU3GeodO-dM9FxDCg/embed?start=false&loop=false&delayms=3000",
  },
  {
    id: 7,
    title: "Aim of Math",
    description: "Some Thing More Descroption here",
    thumbnail: thumbnail9,
    link: "https://docs.google.com/presentation/d/e/2PACX-1vQCpVUVdo_UR7bc9bLQUN-cqqHXzfen6-NYALsx1-Zy5bK_bhxSO29_VUCbF0S2SQ/embed?start=false&loop=false&delayms=3000",
  },
  {
    id: 8,
    title: "Math PK Content",
    description: "Some Thing More Descroption here",
    thumbnail: thumbnail10,
    link: "https://docs.google.com/presentation/d/e/2PACX-1vTVjvHi01eHyP4bHUAoAoJALTWz-L3OYwNcwdmkI5xehylJPxwialAe8QHHBeXATA/embed?start=false&loop=false&delayms=3000",
  },
];

function getContentById(id, set) {
  if (config.backend) {
    axios
      .get(`${config.url}/contents/fetch/?id=${id}`)
      .then(({ data }) => {
        if (data) {
          set(data);
        } else {
          throw new Error("Id not found");
        }
      })
      .catch((error) => {
        set(onlyData.filter((d) => d.id === Number(id))[0]);
      });
  } else {
    set(onlyData.filter((d) => d.id === Number(id))[0]);
  }
}

export { getContentById };
