import axios from "axios";
import config from "../resources/backend";

function checkToken(set, token) {
  if (config.backend) {
    if (token) {
      axios
        .post(`${config.url}/check/auth/`, { token: token })
        .then(({ data }) => {
          if (data.result) {
            set(true);
          } else {
            set(false);
          }
        })
        .catch((error) => {
          console.log(error);
          if (token) {
            set(true);
          } else {
            set(false);
          }
        });
    } else {
      set(false);
    }
  } else {
    if (token) {
      set(true);
    } else {
      set(false);
    }
  }
}

export default checkToken;
