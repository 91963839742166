import {
  Skeleton,
  Stack,
  Typography,
  Unstable_Grid2 as UnstableGrid2,
} from "@mui/material";
import React, { useState } from "react";
import getAcademicRecords from "../service/getAcademicRecords";
const Education = React.lazy(() => import("./Education"));

function Educations() {
  const [constructorHasRun, setConstructorHasRun] = useState(false);
  const [academicRecords, setAcademicRecords] = useState([]);

  if (!constructorHasRun) {
    getAcademicRecords(setAcademicRecords);
    console.log(academicRecords);
    setConstructorHasRun(true);
  }
  return (
    <>
      {academicRecords && (
        <Typography
          gutterBottom
          variant="h5"
          component="div"
          align="center"
          style={{ padding: 40 }}
        >
          Academic Records
        </Typography>
      )}
      <UnstableGrid2>
        {academicRecords.map((record) => {
          return (
            <>
              <React.Suspense
                fallback={
                  <>
                    <Stack spacing={2}>
                      <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
                      <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
                      <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
                      <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
                    </Stack>
                    <br />
                    <br />
                  </>
                }
              >
                <Education
                  degree={record.degree}
                  institute={record.institute}
                  awardingBody={record.awardingBody}
                  passingYear={record.passingYear}
                />
                <div style={{ padding: 10 }}></div>
              </React.Suspense>
            </>
          );
        })}
      </UnstableGrid2>
    </>
  );
}

export default Educations;
