import {
  Box,
  Button,
  Dialog,
  Grid,
  Pagination,
  PaginationItem,
  Typography,
  Unstable_Grid2,
  Zoom,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useContext } from "react";
import Context from "../context/Context";
import Loading from "../loading/Loading";
import getAchivements from "../service/getAchivements";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import CloseIcon from "@mui/icons-material/Close";
import config from "../resources/backend";
const Achivement = React.lazy(() => import("./Achivement"));

export default function Achivements() {
  const [totalAchivements, setTotalAchivements] = useState([[]]);
  const [achivements, setAchivements] = useState([]);
  const [constructorHasRun, setConstructorHasRun] = useState(false);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [currentImage, setCurrentImage] = useState();

  if (!constructorHasRun) {
    getAchivements(setTotalAchivements);
    setConstructorHasRun(true);
  }

  useEffect(() => {
    setTotalPages(totalAchivements.length);
    setAchivements(totalAchivements[page - 1]);
  }, [achivements, page, totalAchivements]);

  const { isMobile } = useContext(Context);

  return (
    <>
      <Typography
        gutterBottom
        variant="h5"
        component="div"
        align="center"
        style={{ padding: 40 }}
      >
        Achivement
      </Typography>
      <Unstable_Grid2
        container
        columnSpacing={{ xs: 1, sm: 3, md: 3 }}
        sx={{
          "& > :not(style)": {
            m: 1,
            width: isMobile ? "100%" : 375,
          },
        }}
      >
        {achivements.map((achivement) => (
          <React.Suspense fallback={<Loading />}>
            <Achivement
              setCurrentImage={setCurrentImage}
              thumbnail={achivement.thumbnail}
              name={achivement.name}
              date={achivement.date}
            />
          </React.Suspense>
        ))}
      </Unstable_Grid2>
      {totalAchivements.length !== 1 && (
        <Grid container alignItems="center" justifyContent="center">
          <Pagination
            defaultPage={page}
            onChange={(event, value) => {
              setPage(value);
            }}
            count={totalPages}
            variant="outlined"
            renderItem={(item) => (
              <PaginationItem
                components={{ previous: ArrowBackIcon, next: ArrowForwardIcon }}
                {...item}
              />
            )}
          />
        </Grid>
      )}
      <Dialog
        open={currentImage}
        onClose={() => setCurrentImage(null)}
        aria-labelledby="image-modal"
        style={{ backgroundColor: "transparent", boxShadow: "none" }}
        overlayStyle={{ backgroundColor: "transparent" }}
      >
        <Zoom in={true} {...{ timeout: 1000 }}>
          <Box
            sx={{
              width: "100%",
            }}
          >
            <div id="image-modal">
              <Button onClick={() => setCurrentImage(null)}>
                <CloseIcon />
              </Button>
              <img
                src={`${config.href}/${currentImage}`}
                alt="CurrentImageClicked"
                srcSet={`${config.href}/${currentImage}`}
                className="img-fluid"
              />
            </div>
          </Box>
        </Zoom>
      </Dialog>
    </>
  );
}
