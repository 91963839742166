import axios from "axios";
import config from "../resources/backend";

export default function getContributions(set) {
  if (config.backend) {
    axios
      .get(`${config.url}/contributions/fetch/pages/`)
      .then(({ data }) => {
        set(data);
      })
      .catch((error) => {
        console.log(error);
        set([[]]);
      });
  } else {
    set([[]]);
  }
}
