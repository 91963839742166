import {
  Zoom,
  Grid,
  Pagination,
  PaginationItem,
  Unstable_Grid2 as UnstableGrid2,
  Typography,
  Stack,
  Skeleton,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import React, { useContext } from "react";
import { useState } from "react";
import Context from "../context/Context";
import getContents from "../service/getContents";
import { useEffect } from "react";
const Content = React.lazy(() => import("./Content"));

export default function Contents() {
  const [constructorHasRun, setConstructorHasRun] = useState(false);
  const [totalItems, setTotalItems] = useState([[]]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [items, setItems] = useState([]);

  if (!constructorHasRun) {
    getContents(setTotalItems);
    setConstructorHasRun(true);
  }

  useEffect(() => {
    setTotalPages(totalItems.length);
    setItems(totalItems[page - 1]);
    console.log(totalItems);
  }, [items, page, totalItems]);

  const { isMobile } = useContext(Context);

  return (
    <>
      <Typography
        gutterBottom
        variant="h5"
        component="div"
        align="center"
        style={{ padding: 40 }}
      >
        Content Development
      </Typography>
      <Zoom in={true} {...{ timeout: 500 }}>
        {/* eslint-disable-next-line */}
        <UnstableGrid2
          container
          columnSpacing={{ xs: 1, sm: 3, md: 3 }}
          sx={{
            "& > :not(style)": {
              m: 1,
              width: isMobile ? "100%" : 375,
            },
          }}
        >
          {items.map((item) => {
            return (
              <React.Suspense
                fallback={
                  <Stack spacing={2}>
                    <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
                    <Skeleton variant="rectangular" height={120} />
                  </Stack>
                }
              >
                <Content
                  id={item.id}
                  title={item.title}
                  description={item.description}
                  thumbnail={item.thumbnail}
                  link={item.link}
                />
              </React.Suspense>
            );
          })}
        </UnstableGrid2>
      </Zoom>
      {totalItems.length !== 1 && (
        <Grid container alignItems="center" justifyContent="center">
          <Pagination
            defaultPage={page}
            onChange={(event, value) => {
              setPage(value);
            }}
            count={totalPages}
            variant="outlined"
            renderItem={(item) => (
              <PaginationItem
                components={{ previous: ArrowBackIcon, next: ArrowForwardIcon }}
                {...item}
              />
            )}
          />
        </Grid>
      )}
    </>
  );
}
