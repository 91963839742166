import axios from "axios";
import config from "../resources/backend";

export default function getAcademicRecords(set) {
  const defaultData = [
    {
      degree: "Masters in Education",
      institute: "Brac University",
      awardingBody: "Brac University",
      passingYear: 2019,
    },
    {
      degree: "Bachelor in Education",
      institute: "Maymenshing BEd College",
      awardingBody: "Bangladesh Open University",
      passingYear: 1999,
    },
    {
      degree: "Master Of Science in Zoology (Fisheries)",
      institute: "Govt. Titumir College, Dhaka",
      awardingBody: "National University, Bangladesh",
      passingYear: 2000,
    },
    {
      degree: "Bachelor of Science (Pass)",
      institute: "Kazi Azimuddin College, Gazipur",
      awardingBody: "National University, Bangladesh",
      passingYear: 1995,
    },
  ];

  if (config.backend) {
    axios
      .get(`${config.url}/education/fetch/`)
      .then(({ data }) => {
        set(data);
      })
      .catch((error) => {
        console.log(error);
        set(defaultData);
      });
  } else {
    set(defaultData);
  }
}
