import { Box, Skeleton } from "@mui/material";

function Loading() {
  return (
    <Box>
      <br />
      <Skeleton />
      <Skeleton animation="wave" />
      <Skeleton animation={false} />
      <br />
    </Box>
  );
}

export default Loading;
