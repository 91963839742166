import React from "react";
import { Route, Routes } from "react-router-dom";
import ErrorBoundary from "../common/ErrorBoundary";
import Loading from "../loading/Loading";
import Home from "../home/Home";
const Admin = React.lazy(() => import("../admin/Admin"));
const UrlError = React.lazy(() => import("../error/UrlError"));
const PreviewContent = React.lazy(() => import("../content/PreviewContent"));

function Routing() {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route
        path="/content/preview/:id"
        element={
          <React.Suspense fallback={<Loading />}>
            <ErrorBoundary>
              <PreviewContent />
            </ErrorBoundary>
          </React.Suspense>
        }
      />
      <Route
        path="/admin/"
        element={
          <React.Suspense fallback={<Loading />}>
            <Admin />
          </React.Suspense>
        }
      />
      <Route
        path="/*"
        element={
          <React.Suspense fallback={<Loading />}>
            <UrlError />
          </React.Suspense>
        }
      />
    </Routes>
  );
}

export default Routing;
